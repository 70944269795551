import type React from 'react';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import pubSubJS from 'pubsub-js';
import { useInstantSearch } from 'react-instantsearch-core';
import Translation from '../../../../general/Translation/Translation';
import { useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import { SEARCH_STATE_CHANGE } from '../../../general/event/eventTypes';

interface Props {
    isSearchPage: boolean;
}

const ShowAllResultsButton: React.FC<Props> = ({ isSearchPage }) => {
    const withSiteUrl = useWithSiteUrl();
    const { results: searchResults } = useInstantSearch();

    const onShowAllResults = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        if (isSearchPage && searchResults) {
            // The customer is already on the search page, we don't need to redirect, instead we'll update the values on the search page
            pubSubJS.publish(SEARCH_STATE_CHANGE, searchResults.query ?? '');
            e.preventDefault();
        }
    }, [isSearchPage, searchResults]);

    return (
        <>
            { (searchResults && !!searchResults.nbHits) && (
                <a
                    onClick={onShowAllResults}
                    className="button button--ghost button--fluid"
                    href={withSiteUrl(`search?query=${searchResults.query}`)}
                >
                    <Translation pageString="overview_page" stringId="show_results" />
                    {' '}
                    ({searchResults.nbHits})
                </a>
            )}
        </>
    );
};

export default ShowAllResultsButton;
