import type React from 'react';
import classNames from 'classnames';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import globalStyles from '../Global.module.scss';
import Translation from '../../../../general/Translation/Translation';
import type { FAQDoc } from '../../../general/Input';
import { window } from '../../../../../js/globals';

export type FAQProps = {
    faqUrl: string;
    setOpen: (value: boolean) => void;
};

export const FAQ: React.FC<FAQProps> = ({ faqUrl, setOpen }) => {
    const { results: untypedResults } = useInstantSearch();
    const searchResults = untypedResults as SearchResults<FAQDoc>;

    const clickHandler = (hit:FAQDoc, results: SearchResults<FAQDoc> | null, position: number) => {
        window?.analytics.track('Element Clicked', {
            category: 'global-search',
            eventType: 'click',
            id: hit.objectID,
            index: results?.index,
            label: 'faq',
            objectID: hit.objectID,
            position: position + 1,
            queryID: searchResults?.queryID,
        });
        setOpen(false);

        return true;
    };

    return (
        <>
            {(searchResults && !!searchResults.nbHits && !!searchResults?.query)
                && (
                    <div className={globalStyles.GlobalSearchBlock}>
                        <h3 className={globalStyles.GlobalSearchBlock__title}><Translation pageString="chunk.header" stringId="faq" /></h3>
                        {searchResults.hits.map((hit, i) => (
                            <a
                                key={hit.objectID}
                                href={`${faqUrl}#question${hit.objectID}`}
                                className={classNames('js-faq-question', globalStyles.GlobalSearchBlock__link)}
                                onClick={() => clickHandler(hit, searchResults, i)}
                            >
                                {hit.question}
                            </a>
                        ))}
                    </div>
                )}
        </>
    );
};
