import type { SearchResults } from 'algoliasearch-helper';
import { window } from '../../../../js/globals';

export const handleRedirects = (searchResults: SearchResults, withSiteUrl: (url: string) => string): void => {
    const toSearch = () => {
        if (window) {
            window.location.href = `${withSiteUrl('search')}?query=${searchResults?.query || ''}`;
        }
    };

    if (!Array.isArray(searchResults?.userData)) {
        toSearch();
        return;
    }

    let redirect = false;

    try {
        searchResults?.userData.forEach((data: Record<string, unknown>) => {
            Object.entries(data).forEach(([key, value]) => {
                if (key === 'redirect' && typeof value === 'string') {
                    const url = new URL(value);
                    if (window && url.hostname === new URL(window.location.href).hostname) {
                        window.location.href = url.toString();
                        redirect = true;
                    }
                }

                if (key === 'redirect_id' && !isNaN(Number(value)) && window) {
                    window.location.href = withSiteUrl(`redirect-by-page-id/${String(value)}`);
                    redirect = true;
                }
            });
        });
    } catch (e) {
        // Do nothing
    }

    if (!redirect) {
        toSearch();
    }
};
