import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import * as pubSubJS from 'pubsub-js';
import type { FormEvent, ChangeEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import { useInstantSearch, useSearchBox } from 'react-instantsearch-core';
import styles from './Input.module.scss';
import { document } from '../../../../../js/globals';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import { SEARCH_PAGE_LOADED, SEARCH_STATE_CHANGE, URL_CHANGE } from '../../../general/event/eventTypes';
import { useIsInitialRequestMobile, useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import { useIsMobile } from '../../../../general/hooks/useSize';
import { handleRedirects } from '../../../general/url/handleRedirects';
import useTranslate from '../../../../general/Translation/hooks/UseTranslate';

interface SearchBoxProps {
    isSearchPage: boolean;
    open: boolean;
    setOpen: (value: boolean) => void;
}

const Input: React.FC<SearchBoxProps> = ({ isSearchPage, open, setOpen }) => {
    const { clear, query, refine } = useSearchBox();
    const { results } = useInstantSearch();
    const withSiteUrl = useWithSiteUrl();
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const searchFormRef = useRef<HTMLFormElement | null>(null);
    const searchText = useTranslate()('chunk.header', 'vind_jouw_cadeau');
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => refine(event.currentTarget.value);

    const doOpen = useCallback(() => {
        setOpen(true);
        searchInputRef.current?.focus();
    }, [setOpen]);

    useEffect(() => {
        const searchIcon = document?.querySelector('.js-searchBox');

        if (!document && !searchIcon) {
            return () => {};
        }

        searchIcon?.addEventListener('click', doOpen);

        return () => searchIcon?.removeEventListener('click', doOpen);
    }, [doOpen]);

    const onCloseSearch = useCallback((resetSearch: boolean) => {
        if (resetSearch) {
            clear();
        }

        searchInputRef.current?.blur();
        setOpen(false);
    }, [clear, setOpen]);

    const submitSearch = (e: FormEvent<EventTarget>) => {
        e.preventDefault();

        if (isSearchPage) {
            pubSubJS.publish(SEARCH_STATE_CHANGE, query);
        } else {
            handleRedirects(results, withSiteUrl);
        }
    };

    useEffect(() => {
        if (document?.activeElement === searchInputRef.current) {
            const searchQuery = searchInputRef.current?.value;

            if (searchQuery) {
                refine(searchQuery);
            }

            setOpen(true);
        }
    }, [refine, setOpen]);

    useEffect(() => {
        pubSubJS.subscribe(URL_CHANGE, (_: unknown, { closeSearch, query: searchQuery }) => {
            if (closeSearch) {
                setOpen(false);
                searchInputRef.current?.blur();
            }

            if (isSearchPage) {
                searchInputRef.current?.blur();

                if (typeof searchQuery === 'string') {
                    refine(searchQuery);
                }
            }
        });

        return () => {
            pubSubJS.unsubscribe(URL_CHANGE);
        };
    }, [isSearchPage, refine, setOpen]);

    useEffect(() => {
        pubSubJS.subscribe(SEARCH_STATE_CHANGE, () => {
            onCloseSearch(false);
        });

        return () => {
            pubSubJS.unsubscribe(SEARCH_STATE_CHANGE);
        };
    }, [onCloseSearch]);

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && document) {
                onCloseSearch(true);
            }
        };

        const onScrollEvent = () => {
            searchInputRef.current?.blur();
        };

        document?.body.addEventListener('keydown', listener);

        if (isMobileOrTablet) {
            document?.body.addEventListener('touchstart', onScrollEvent);
        }

        return () => {
            document?.body.removeEventListener('keydown', listener);
            document?.body.removeEventListener('touchstart', onScrollEvent);
        };
    }, [isMobileOrTablet, onCloseSearch]);

    useEffect(() => {
        if (isSearchPage) {
            pubSubJS.publish(SEARCH_PAGE_LOADED);
        }
    }, [isSearchPage]);

    const onSearchReset = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        clear();
    };

    return (
        <form
            ref={searchFormRef}
            className={classNames(styles.Form, { [styles.Form__open as string]: open })}
            onSubmit={submitSearch}
            name="search"
            action={withSiteUrl('search')}
        >
            {open && (
                <button
                    className={classNames(styles.Button, styles['Button__left-side'])}
                    onClick={() => onCloseSearch(true)}
                    type="button"
                    aria-label="Close search"
                >
                    <Icon name='fa-chevron-left' />
                </button>
            )}
            <input
                className={classNames(styles.Input, { [styles.Input__open as string]: open })}
                onChange={onInputChange}
                onFocus={doOpen}
                placeholder={searchText}
                ref={searchInputRef}
                type="search"
                name="query"
                value={query}
                autoComplete="off"
            />
            {query
                ? (
                    <button
                        className={classNames(styles.Button, styles['Button__right-side'], { [styles.Button__open as string]: open })}
                        onClick={onSearchReset}
                        type="button"
                        aria-label="Remove search"
                    >
                        <Icon name='fa-xmark' />
                    </button>
                )
                : (
                    <button
                        className={classNames([styles.Button, styles['Button__right-side']], { [styles.Button__open as string]: open })}
                        type="submit"
                        aria-label="Search button"
                    >
                        <Icon name='fa-magnifying-glass' />
                    </button>
                )}
        </form>

    );
};

export default Input;
