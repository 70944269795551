import type React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { IntlProvider } from 'react-intl';
import type { SearchClient } from 'algoliasearch';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import type { WebshopContextData } from '../../../general/WebshopContext/WebshopContextProvider';
import WebshopContextProvider from '../../../general/WebshopContext/WebshopContextProvider';
import type { Translations } from '../../../general/Translation/types/translations';
import AlgoliaGlobalSearch from './AlgoliaGlobalSearch';

interface AppProps {
    algoliaServerState?: InstantSearchServerState;
    categoryIndexName: string;
    contextData: WebshopContextData;
    currency: string;
    faqIndexName: string;
    faqUrl: string;
    isSearchPage: boolean;
    locale: string;
    productIndexName: string;
    querySuggestionsIndexName: string;
    searchClient: SearchClient;
    tracker?: Tracker | undefined;
    translations: Translations;
}

const App: React.FC<AppProps> = ({
    algoliaServerState,
    categoryIndexName,
    contextData,
    currency,
    faqIndexName,
    faqUrl,
    isSearchPage,
    locale,
    productIndexName,
    querySuggestionsIndexName,
    searchClient,
    tracker,
    translations,

}) => (
    <IntlProvider locale={locale}>
        <TranslationProvider dictionary={translations}>
            <WebshopContextProvider data={contextData}>
                <AlgoliaGlobalSearch
                    algoliaServerState={algoliaServerState}
                    categoryIndexName={categoryIndexName}
                    currency={currency}
                    faqIndexName={faqIndexName}
                    faqUrl={faqUrl}
                    isSearchPage={isSearchPage}
                    productIndexName={productIndexName}
                    querySuggestionsIndexName={querySuggestionsIndexName}
                    searchClient={searchClient}
                    tracker={tracker}
                />
            </WebshopContextProvider>
        </TranslationProvider>
    </IntlProvider>
);

export default App;
