import type { Tracker } from '@yoursurprise/segment-analytics';
import { isMobilePortrait } from '../../../../js/util/sizes';
import useAnalytics from './useAnalytics';

export type AnalyticsProps = {
    currency: string;
    isGlobalSearch?: boolean;
    tracker: Tracker | undefined;
};

export const Analytics: React.FC<AnalyticsProps> = (props) => {
    useAnalytics(props);

    return null;
};

const DEVICE_MOBILE = 'mobile';
const DEVICE_DESKTOP = 'desktop';

type AlgoliaAnalyticsDevice = typeof DEVICE_MOBILE | typeof DEVICE_DESKTOP;

export const addDeviceTag = (tags: string[], isRule = false) => {
    const device: AlgoliaAnalyticsDevice = isMobilePortrait() ? DEVICE_MOBILE : DEVICE_DESKTOP;

    return [
        ...tags,
        isRule ? `device_${device}` : `device:${device}`,
    ];
};
