import type { Product, Tracker } from '@yoursurprise/segment-analytics';
import mapHitToProduct from './Analytics/mapHitToProduct';
import updateAlgoliaCookie from './Analytics/updateAlgoliaCookie';
import type { AlgoliaProduct } from './Input';

export const createOnProductClicked = (tracker: Tracker | undefined, currency: string, index?: string, queryID?: string) => (hit: AlgoliaProduct & { __queryID?: string }, position: number): boolean => {
    const usedQueryId = hit.__queryID ?? queryID;

    tracker?.trackProductClicked({
        ...mapHitToProduct(hit, currency, position + 1),
        index,
        queryID: usedQueryId,
    } as Product);

    if (index && usedQueryId) {
        updateAlgoliaCookie(usedQueryId, index, hit.combinedId);
    }

    return true;
};
