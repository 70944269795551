import type React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import classNames from 'classnames';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import type { AlgoliaProduct } from '../../../general/Input';
import globalStyles from '../Global.module.scss';
import styles from './Products.module.scss';
import ShowAllResultsButton from '../Buttons/ShowAllResultsButton';
import { createOnProductClicked } from '../../../general/createOnProductClicked';
import Translation from '../../../../general/Translation/Translation';
import { MemoProductBox } from '../../../../general/ProductBox/ProductBox';

interface ProductsProps {
    currency: string;
    isSearchPage: boolean;
    tracker?: Tracker | undefined;
}

const Products: React.FC<ProductsProps> = ({
    currency,
    isSearchPage,
    tracker,
}) => {
    const { indexUiState, results: untypedResults } = useInstantSearch();
    const searchResults = untypedResults as SearchResults<AlgoliaProduct>;
    const onProductClicked = createOnProductClicked(tracker, currency, untypedResults.index, searchResults?.queryID);
    const hits = searchResults?.hits ?? [];
    const hasSearchQuery = indexUiState?.query;

    return (
        <div className={classNames({ [styles.Container_reverse as string]: hasSearchQuery }, styles.Container)}>
            <div className={classNames(globalStyles.GlobalSearchBlock, styles.Products)}>
                {hits.length
                    ? (
                        <>
                            <h3 className={globalStyles.GlobalSearchBlock__title}>
                                {hasSearchQuery
                                    ? <Translation pageString="overview_page" stringId="gifts_found" />
                                    : <Translation pageString="overview_page" stringId="popular_products" />}
                            </h3>
                            <div className="product-list product-list--horizontal is-grid has-col-3-xl has-col-2-lg has-col-1">
                                {hits.map((hit, position) => (
                                    <MemoProductBox
                                        alt={hit.productH1}
                                        key={hit.productId}
                                        amount={hit.combiArticleCount}
                                        href={hit.url}
                                        image={hit.image}
                                        onProductClicked={() => onProductClicked(hit, position)}
                                        price={{ currency, value: hit.price.current }}
                                        priceFrom={{ currency, value: hit.price.from }}
                                        lowestPrice={hit.combiArticleCount > 1 ? {
                                            currency,
                                            value: hit.lowestPrice,
                                        } : undefined}
                                        title={hit.name}
                                        isHorizontal
                                        hasFreeCoolerNotification={false}
                                    />
                                ))}
                            </div>
                            {hasSearchQuery && (
                                <div className="button__stack mt-8 hide-xs">
                                    <ShowAllResultsButton isSearchPage={isSearchPage}/>
                                </div>
                            )}
                        </>
                    )
                    : (

                        <>
                            <h3 className={globalStyles.GlobalSearchBlock__title}>
                                <Translation pageString="overview_page" stringId="gifts_found" />
                            </h3>
                            <p className={globalStyles.GlobalSearchBlock__empty}>
                                <Translation pageString="overview_page" stringId="no_gifts_found" />
                            </p>
                        </>
                    )}

            </div>
        </div>
    );
};

export default Products;
