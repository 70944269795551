import type React from 'react';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import globalStyles from '../Global.module.scss';
import Translation from '../../../../general/Translation/Translation';
import type { CategoryDoc } from '../../../general/Input';
import { useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import { window } from '../../../../../js/globals';

const Category: React.FC = () => {
    const withSiteUrl = useWithSiteUrl();
    const { results: untypedResults } = useInstantSearch();
    const searchResults = untypedResults as SearchResults<CategoryDoc>;
    return (
        <>
            {!!searchResults?.query && (
                <div className={globalStyles.GlobalSearchBlock}>
                    <h3 className={globalStyles.GlobalSearchBlock__title}><Translation pageString="overview_page" stringId="assortment_group" /></h3>
                    {(searchResults && !!searchResults.nbHits)
                        ? (
                            <>
                                {searchResults.hits.map((hit, i) => (
                                    <a
                                        key={hit.objectID}
                                        href={withSiteUrl(hit.uri)}
                                        className={globalStyles.GlobalSearchBlock__link}
                                        onClick={() => window?.analytics.track('Element Clicked', {
                                            category: 'global-search',
                                            eventType: 'click',
                                            id: hit.objectID,
                                            index: searchResults.index,
                                            label: 'category',
                                            objectID: hit.objectID,
                                            position: i + 1,
                                            queryID: searchResults?.queryID,
                                        })}
                                    >
                                        {hit.name}
                                    </a>
                                ))}
                            </>
                        )
                        : (
                            <p>
                                <Translation pageString="overview_page" stringId="no_categories" />
                            </p>
                        )}
                </div>
            )}
        </>
    );
};

export default Category;
