import { Tracker } from '@yoursurprise/segment-analytics';
import { BrowserRouter } from 'react-router-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import App from './Components/App';
import { createSearchClient } from '../general/searchClient';

const ssrElement = document?.getElementById('algolia-global-ssr');
const globalElement = document?.getElementById('algolia-global-search');

const searchClient = createSearchClient(
    window?.ALGOLIA_DATA?.appID ?? '',
    window?.ALGOLIA_DATA?.searchApiKey ?? '',
);

if (document && window && window.ALGOLIA_DATA) {
    const tracker = new Tracker();

    if (ssrElement) {
        hydrateRoot(
            ssrElement,
            <BrowserRouter>
                <App
                    algoliaServerState={{ initialResults: {} }}
                    searchClient={searchClient}
                    contextData={window.siteMetadata}
                    locale={window.ALGOLIA_DATA.locale}
                    translations={window.ALGOLIA_DATA.translations}
                    currency={window.ALGOLIA_DATA.currency}
                    faqIndexName={window.ALGOLIA_DATA.faqIndexName}
                    productIndexName={window.ALGOLIA_DATA.productIndexName}
                    querySuggestionsIndexName={window.ALGOLIA_DATA.querySuggestionsIndexName}
                    categoryIndexName={window.ALGOLIA_DATA.categoryIndexName}
                    faqUrl={window.ALGOLIA_DATA.faqUrl}
                    tracker={tracker}
                    isSearchPage={window.pageType === 'Search'}
                />
            </BrowserRouter>,
        );
    } else if (globalElement) {
        const root = createRoot(globalElement);
        root.render(
            <BrowserRouter>
                <App
                    searchClient={searchClient}
                    contextData={window.siteMetadata}
                    locale={window.ALGOLIA_DATA.locale}
                    translations={window.ALGOLIA_DATA.translations}
                    currency={window.ALGOLIA_DATA.currency}
                    faqIndexName={window.ALGOLIA_DATA.faqIndexName}
                    productIndexName={window.ALGOLIA_DATA.productIndexName}
                    querySuggestionsIndexName={window.ALGOLIA_DATA.querySuggestionsIndexName}
                    categoryIndexName={window.ALGOLIA_DATA.categoryIndexName}
                    faqUrl={window.ALGOLIA_DATA.faqUrl}
                    tracker={tracker}
                    isSearchPage={window.pageType === 'Search'}
                />
            </BrowserRouter>,
        );
    }
}
