import type { Tracker } from '@yoursurprise/segment-analytics';
import { useState } from 'react';
import debounce from 'debounce';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import mapHitToProduct from './mapHitToProduct';
import type { AnalyticsProps } from './Analytics';
import { window } from '../../../../js/globals';
import type { AlgoliaProduct } from '../Input';

const trackProductListViewed: (tracker?: Tracker | undefined, ...x:Parameters<Tracker['trackProductListViewed']>) => void = (
    tracker,
    listName,
    products,
    additionalData = {},
) => {
    tracker?.trackProductListViewed(listName, products, additionalData);
};

const trackProductListFiltered: (tracker?: Tracker | undefined, ...x:Parameters<Tracker['trackProductListFiltered']>) => void = (
    tracker,
    listName,
    filters,
    sorts,
    products,
    additionalData = {},
) => {
    tracker?.trackProductListFiltered(listName, filters, sorts, products, additionalData);
};

const debouncedGa = debounce((ga: NonNullable<Window['ga']>, ...params: Parameters<NonNullable<Window['ga']>>) => {
    ga(...params);
}, 500);

const debounceProductListViewed = debounce(trackProductListViewed, 800);
const debounceProductListFiltered = debounce(trackProductListFiltered, 200);

const haveFiltersChanged = <TRange, TRefinement, TSortBy>(oldRange: TRange, range: TRange, refinementList: TRefinement, oldRefinements: TRefinement, oldSortBy: TSortBy, sortBy: TSortBy) => (
    oldRange !== range || refinementList !== oldRefinements || oldSortBy !== sortBy
);

const useAnalytics = ({ currency, isGlobalSearch = false, tracker }: AnalyticsProps): void => {
    const { indexUiState, results: searchResults } = useInstantSearch();
    const { range, refinementList, sortBy } = indexUiState || {};

    const [oldRange, setOldRange] = useState(range);
    const [oldRefinements, setOldRefinements] = useState(refinementList);
    const [oldSortBy, setOldSortBy] = useState(sortBy);

    // We prevent an actual search from happening when the global search has not been opened but has been loaded
    // In this case, we don't want to send any events, since there was no interaction at all.
    // To do so, we check if an index was queried within the search results.
    if (!indexUiState || !searchResults.index) {
        return;
    }

    const { hitsPerPage, page } = searchResults || {};

    const abTestId = searchResults.abTestID ?? null;
    const variationId = searchResults.abTestVariantID ?? null;

    if (abTestId !== null && variationId !== null && window?.ga) {
        debouncedGa(window.ga, 'send', 'event', 'Algolia-AB', `Experiment-${abTestId}`, `Variation-${variationId}`, { nonInteraction: true });
    }

    const products = (searchResults as SearchResults<AlgoliaProduct>).hits.map((hit, position) => (
        // The hits array will only contain the products of the new page,
        // so we have to adjust the position with the number of pages (0-indexed) and products per pages
        mapHitToProduct(hit, currency, (page * hitsPerPage) + position + 1)
    ));

    const additionalProperties: Record<string, unknown> & {
        filters?: string;
    } = {
        index: searchResults.index,
        queryID: searchResults.queryID,
    };

    additionalProperties.isGlobalSearch = isGlobalSearch;

    // We also want to log empty searches, so have to check the type
    if (typeof searchResults?.query === 'string') {
        additionalProperties.searchQuery = searchResults.query;
    }

    if (window?.pageId) {
        additionalProperties.pageId = window.pageId;
    }

    if (window?.pageType) {
        additionalProperties.pageType = window.pageType;
    }

    // The page is 0-indexed, but we want it to be more human-friendly in the Segment data
    additionalProperties.productListPage = (page ?? 0) + 1;

    if (range || refinementList || sortBy) {
        const usedFilters: Record<string, unknown> = {
            ...range,
            ...refinementList,
        };

        if (sortBy) {
            usedFilters.sortBy = sortBy;
        }

        // We want the filters property to be a single value and not an object
        // so it will serialize nicely to BigQuery, otherwise every object key will create a new column
        additionalProperties.filters = JSON.stringify(usedFilters);
    }

    const listName = isGlobalSearch
        ? 'global-search'
        : window?.pageName ?? 'algolia-search';

    debounceProductListViewed(
        tracker,
        listName,
        products,
        additionalProperties,
    );

    if (!haveFiltersChanged(oldRange, range, refinementList, oldRefinements, oldSortBy, sortBy)) {
        return;
    }

    setOldRange(range);
    setOldRefinements(refinementList);
    setOldSortBy(sortBy);

    const filters = Object.entries(refinementList || {}).flatMap(([type, values]) => (
        (values || []).map((value) => ({
            type,
            value,
        }))
    ));

    const rangeFilters = Object.entries(range || {}).flatMap(([type, values]) => (
        Object.entries(values).map(([key, value]) => ({
            type,
            value: `${key}:${value}`,
        }))
    ));

    const sorts = [];
    if (typeof sortBy === 'string') {
        const [value, type] = sortBy.split('_').reverse();
        sorts.push({
            type,
            value,
        });
    }
    const allFilters = [...filters, ...rangeFilters];

    if (allFilters.length === 0 && sorts.length === 0) {
        return;
    }

    // We don't want to track filters as part of these properties
    // since they're passed to the function separately
    delete additionalProperties.filters;

    debounceProductListFiltered(
        tracker,
        'algolia-search',
        allFilters,
        sorts,
        products,
        additionalProperties,
    );
};

export default useAnalytics;
