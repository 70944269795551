import Cookie from 'js-cookie';

export type AlgoliaData = Record<string, {
    index: string;
    queryId: string;
    t?: string;
}>;

// We apply this before adding a new item, so in total there will be a maximum of 20 items.
const MAX_DATA_AMOUNT = 19;

export const ALGOLIA_QUERY_DATA_COOKIE = 'ALGOLIA_QUERY_DATA';

export const getAlgoliaData = (): AlgoliaData => {
    let algoliaData: AlgoliaData = {};

    try {
        algoliaData = JSON.parse(Cookie.get(ALGOLIA_QUERY_DATA_COOKIE) ?? '{}') as AlgoliaData;
    } catch (e) {
        // With broken data, reset it
    }

    const size = Object.keys(algoliaData).length;
    if (size > MAX_DATA_AMOUNT) {
        const sortedData = Object.entries(algoliaData).sort(([, aValue], [, bValue]) => {
            if (aValue.t === undefined && bValue.t === undefined) {
                return 0;
            }

            if (aValue.t === undefined) {
                return -1;
            }

            if (bValue.t === undefined) {
                return 1;
            }

            if (aValue.t === bValue.t) {
                return 0;
            }

            return new Date(aValue.t) > new Date(bValue.t) ? 1 : -1;
        });

        algoliaData = sortedData.slice(size - MAX_DATA_AMOUNT).reduce<AlgoliaData>((carry, current) => ({
            ...carry,
            [current[0]]: current[1],
        }), {});
    }

    return algoliaData;
};

const updateAlgoliaCookie = (queryId: string | undefined, index: string, combiId: string): void => {
    if (!queryId) {
        return;
    }
    const algoliaData = getAlgoliaData();

    algoliaData[combiId] = {
        index,
        queryId,
        t: new Date().toISOString().split('.')[0],
    };

    const d = new Date();
    // Add 7 days
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
    Cookie.set(ALGOLIA_QUERY_DATA_COOKIE, JSON.stringify(algoliaData), { expires: d });
};

export default updateAlgoliaCookie;
