import type React from 'react';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import globalStyles from '../Global.module.scss';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import type { QuerySuggestionsDoc } from '../../../general/Input';
import Translation from '../../../../general/Translation/Translation';
import { useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import { window } from '../../../../../js/globals';

const QuerySuggestions: React.FC = () => {
    const withSiteUrl = useWithSiteUrl();
    const { results: untypedResults } = useInstantSearch();
    const searchResults = untypedResults as SearchResults<QuerySuggestionsDoc>;
    return (
        <>
            {(searchResults && !!searchResults.nbHits && !searchResults?.query)
                && (
                    <div className={globalStyles.GlobalSearchBlock}>
                        <h3 className={globalStyles.GlobalSearchBlock__title}>
                            <Translation pageString="overview_page" stringId="search_suggestion" />
                        </h3>
                        {searchResults.hits.map((hit) => (
                            <a
                                key={hit.objectID}
                                className={globalStyles.GlobalSearchBlock__link}
                                href={withSiteUrl(`search?query=${hit.query}`)}
                                onClick={() => window?.analytics.track('Element Clicked', { category: 'global-search', id: hit.objectID, label: 'query-suggestions' })}
                            >
                                <span>{hit.query}</span>
                                <Icon name='fa-magnifying-glass' />
                            </a>
                        ))}
                    </div>
                )}
        </>
    );
};

export default QuerySuggestions;
