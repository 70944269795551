import classNames from 'classnames';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import { Configure } from 'react-instantsearch-core';
import type React from 'react';
import { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import type { SearchClient } from 'algoliasearch';
import debounce from 'debounce';
import type { Tracker } from '@yoursurprise/segment-analytics';
import type { InstantSearchOptions } from 'instantsearch.js/es/lib/InstantSearch';
import styles from './App.module.scss';
import ComplementaryResults from './ComplementaryResults/ComplementaryResults';
import { Analytics, addDeviceTag } from '../../general/Analytics/Analytics';
import { document } from '../../../../js/globals';
import useHasAnalytics from '../../general/Analytics/useHasAnalytics';
import { useIsMobile } from '../../../general/hooks/useSize';
import AlgoliaProvider from '../../general/InstantSearch/AlgoliaProvider';
import ShowAllResultsButton from './Buttons/ShowAllResultsButton';
import Results from './Results/Products';
import Input from './Input/Input';
import { useIsInitialRequestMobile } from '../../../general/WebshopContext/WebshopContext';

const DEBOUNCE_TIME = 400;

const debounceUpdate = debounce((tracker: Tracker | undefined, query: string) => {
    if (query !== '') {
        tracker?.trackProductsSearched(query);
    }
}, DEBOUNCE_TIME);

interface AlgoliaGlobalSearchProps {
    algoliaServerState?: InstantSearchServerState;
    categoryIndexName: string;
    currency: string;
    faqIndexName: string;
    faqUrl: string;
    isSearchPage: boolean;
    productIndexName: string;
    querySuggestionsIndexName: string;
    searchClient: SearchClient;
    tracker?: Tracker | undefined;
}

const AlgoliaGlobalSearch: React.FC<AlgoliaGlobalSearchProps> = ({
    algoliaServerState,
    categoryIndexName,
    currency,
    faqIndexName,
    faqUrl,
    isSearchPage,
    productIndexName,
    querySuggestionsIndexName,
    searchClient,
    tracker,
}) => {
    const analytics = useHasAnalytics();
    const [open, setOpen] = useState<boolean>(false);
    const shouldDoRequest = useRef<boolean>(false);
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());

    // Within a use callback because a rerender would retrigger opening the search due to a dependency in Input.tsx
    const wrappedSetOpen = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
        shouldDoRequest.current = isOpen;
    }, []);

    useEffect(() => {
        const noScrollClasses = ['util__noScroll', 'util__noScroll--noScrollbars'];

        if (document && open && isMobileOrTablet) {
            document.body.classList.add(...noScrollClasses);
        }

        const listener = (event: MouseEvent) => {
            const searchElement = document?.getElementsByClassName('js-global-search');
            const { target } = event;

            if (!searchElement || searchElement.length === 0 || !(target instanceof HTMLElement)) {
                return;
            }

            const doesAnyContain = Array.from(searchElement).some((element) => element.contains(target));

            if (!doesAnyContain) {
                wrappedSetOpen(false);
            }
        };

        document?.addEventListener('mousedown', listener);

        return () => {
            document?.removeEventListener('mousedown', listener);

            if (document && open && isMobileOrTablet) {
                document?.body.classList.remove(...noScrollClasses);
            }
        };
    }, [open, isMobileOrTablet, wrappedSetOpen]);

    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
    const searchWhileOpenSearchClient: SearchClient = useMemo(() => ({
        ...searchClient,
        search: async (...args) => {
            // We don't want to search on the server, only when the client has opened the search
            if (shouldDoRequest.current) {
                return searchClient.search(...args);
            }

            return Promise.resolve({ results: args[0].map(() => ({
                exhaustiveNbHits: false,
                hits: [],
                hitsPerPage: 0,
                nbHits: 0,
                nbPages: 0,
                page: 0,
                params: '',
                processingTimeMS: 0,
                query: '',
            })) });
        },
    }), [searchClient]);

    const onStateChange: InstantSearchOptions['onStateChange'] = ({ setUiState, uiState }) => {
        debounceUpdate(tracker, uiState[productIndexName]?.query ?? '');
        setUiState(uiState);
    };

    return (
        <div className={classNames({ [styles.App as string]: open, 'js-global-search': open })}>
            <AlgoliaProvider
                algoliaSearchClient={searchWhileOpenSearchClient}
                onStateChange={onStateChange}
                indexName={productIndexName}
                algoliaServerState={algoliaServerState}
            >
                <Input setOpen={wrappedSetOpen} open={open} isSearchPage={isSearchPage} />
                {open && (
                    <>
                        <Configure
                            hitsPerPage={6}
                            getRankingInfo
                            analytics={analytics}
                            analyticsTags={addDeviceTag(['page:global'])}
                            clickAnalytics={analytics}
                            ruleContexts={addDeviceTag(['page_global'], true)}
                        />
                        <div className={styles.App__main}>
                            <div className={styles.App__content}>
                                <ComplementaryResults
                                    querySuggestionsIndexName={querySuggestionsIndexName}
                                    faqIndexName={faqIndexName}
                                    categoryIndexName={categoryIndexName}
                                    faqUrl={faqUrl}
                                    setOpen={wrappedSetOpen}
                                />
                                <Results
                                    isSearchPage={isSearchPage}
                                    currency={currency}
                                    tracker={tracker}
                                />
                            </div>
                        </div>
                        <div className={classNames(styles.App__noDisplayOnDesktop, styles.App__showMoreButton)}>
                            <ShowAllResultsButton isSearchPage={isSearchPage} />
                        </div>
                    </>
                )}
                <Analytics currency={currency} tracker={tracker} isGlobalSearch />
            </AlgoliaProvider>
        </div>
    );
};

export default AlgoliaGlobalSearch;
