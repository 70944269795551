import type React from 'react';
import classNames from 'classnames';
import { Configure, Index } from 'react-instantsearch-core';
import QuerySuggestions from './QuerySuggestions';
import { FAQ } from './FAQ';
import Category from './Category';
import styles from './ComplementaryResults.module.scss';

interface ComplementaryResultsProps {
    categoryIndexName: string;
    className?: string;
    faqIndexName:string;
    faqUrl:string;
    querySuggestionsIndexName: string;
    setOpen: (value: boolean) => void;
}

const ComplementaryResults: React.FC<ComplementaryResultsProps> = ({
    categoryIndexName,
    className,
    faqIndexName,
    faqUrl,
    querySuggestionsIndexName,
    setOpen,
}) => (
    <div className={classNames(styles.ComplementaryResults, className)}>

        <Index indexName={querySuggestionsIndexName}>
            <Configure
                hitsPerPage={5}
                ruleContexts={['page_global']}
            />
            <QuerySuggestions />
        </Index>
        <Index indexName={categoryIndexName}>
            <Configure
                hitsPerPage={5}
                ruleContexts={['page_global']}
            />
            <Category />
        </Index>
        <Index indexName={faqIndexName}>
            <Configure
                hitsPerPage={3}
                ruleContexts={['page_global']}
            />
            <FAQ faqUrl={faqUrl} setOpen={setOpen} />
        </Index>

    </div>
);

export default ComplementaryResults;
